import { Button, DialogTitle, Rating, TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import { TableDialog } from "./TableDialog.tsx";
import { addToWhiteListApi } from "../../api/whiteList.ts";

const RATING_SIZE = 10;

export const WhiteListButton = ({ params }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState<number | null>(5);
  const [textfieldValue, setTextfieldValue] = useState<string>("");

  const rowData = params.row;
  const {
    whiteList,
    coreProduct: { uid },
  } = rowData;

  const onClick = () => {
    const whiteListData = {
      uid,
      rating: ratingValue || 0,
      note: textfieldValue,
    };

    addToWhiteListApi(whiteListData);
    onClose();
  };

  const onTextFieldChange = (event) => {
    setTextfieldValue(event.target.value);
  };

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const content = whiteList ? (
    "Уже в White-List"
  ) : (
    <Button variant="contained" onClick={handleOpenPopup}>
      Добавить
    </Button>
  );

  return (
    <>
      {content}
      <TableDialog isOpen={isOpen} onClose={onClose}>
        <DialogTitle style={{ paddingTop: 0 }} align="center">
          Добавление в White List
        </DialogTitle>
        <div className="dialog-rating">
          <Rating
            name="customized-10"
            value={ratingValue}
            max={RATING_SIZE}
            size="large"
            onChange={(event, newValue) => {
              setRatingValue(newValue);
            }}
          />
        </div>
        <div className="dialog-textfield">
          <TextField
            id="outlined-basic"
            label="Примечание"
            variant="outlined"
            fullWidth
            multiline
            maxRows={5}
            value={textfieldValue}
            onChange={onTextFieldChange}
          />
        </div>
        <Button variant="contained" onClick={onClick}>
          Добавить в White List
        </Button>
      </TableDialog>
    </>
  );
};
