import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth.tsx";
import { removeAuthToken } from '../utils/localStorageAuth.ts';

function Logout() {
  const { setAuth } = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    setAuth(false);
    removeAuthToken();
    navigate('/login');
  }, [setAuth, navigate])

  return (
    <div>Logout</div>
  )
}

export default Logout
