// Преобразовываем именование полей фильтров для апи
export const convertFilters = (filters) => {
  const {
    code,
    brand,
    vendorCount,
    qtyCount,
    avgCost,
    minCost,
    countSearch,
    whiteList,
  } = filters;

  let whiteListValue: string | boolean = "";

  if (whiteList === "-") whiteListValue = false;
  if (whiteList === "+") whiteListValue = true;

  return {
    code,
    brand,
    vendor_count_gte: vendorCount,
    qty_count_gte: qtyCount,
    avg_cost_gte: avgCost,
    min_cost_gte: minCost,
    count_search_gte: countSearch,
    white_list_is_null: whiteListValue,
  };
};

// удаляем из объекта пустые значения, чтобы они не уходили в запросе
export const removeEmptyKeys = (filters) => {
  const filledFilters = {};

  Object.keys(filters).forEach((key) => {
    if (filters[key] === "") return;

    filledFilters[key] = filters[key];
  });

  return filledFilters;
};

export const preparePaginationModelParams = (paginationModel) => {
  // в таблице DataGrid нумерация страниц начинается с нуля,
  // добавляем 1 для соответствия с апишкой
  const page = paginationModel.page + 1;
  const per_page = paginationModel.pageSize;

  return {
    page,
    per_page,
  };
};
