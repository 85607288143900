import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getAnalyticsListApi } from "../api/analytics.ts";
import { IAnalyticItem } from "../types/analyticItem.ts";
import { Box, LinearProgress, Typography } from "@mui/material";
import {
  AnalyticsCustomToolbar,
  initialFilterState,
} from "../components/DataGrid/AnalyticsCustomToolbar.tsx";
import { analyticsGridColumns } from "../components/DataGrid/utils.tsx";
import {
  convertFilters,
  preparePaginationModelParams,
  removeEmptyKeys,
} from "../utils/prepareAnalyticQueryParams.ts";
import { getPageSize, setPageSize } from "../utils/localStoragePageSize.ts";

const START_PAGE = 0;
const PAGE_SIZE_COUNT = Number(getPageSize()) || 100;
const DEFAULT_PAGINATION_MODEL = {
  page: START_PAGE,
  pageSize: PAGE_SIZE_COUNT,
};

const Analytics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [analyticsData, setAnalyticsData] = useState<IAnalyticItem[]>([]);
  const [rowNumber, setRowNumber] = useState(0);
  const [filtersState, setFiltersState] = useState(initialFilterState);
  const [paginationModel, setPaginationModel] = useState(
    DEFAULT_PAGINATION_MODEL
  );
  const [isFiltersWasApplied, setIsFiltersWasApplied] = useState(false);

  const fetchAnalyticsList = (params?) => {
    getAnalyticsListApi(params).then(async (res) => {
      const json = await res.json();

      if (res.ok) {
        setAnalyticsData(json.data);
        setRowNumber(json.meta.count);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  };

  const onApplyFilters = (filters) => {
    setFiltersState(filters);
    setIsFiltersWasApplied(true);

    const convertedFilters = convertFilters(filters);
    const filledFilters = removeEmptyKeys(convertedFilters);
    const paginationModelParams = preparePaginationModelParams(paginationModel);
    const params = {
      ...filledFilters,
      ...paginationModelParams,
    };

    setIsLoading(true);

    fetchAnalyticsList(params);
  };

  const ModifiedCustomToolbar = () => {
    return (
      <AnalyticsCustomToolbar
        filtersState={filtersState}
        onApplyFilters={onApplyFilters}
        isLoading={isLoading}
      />
    );
  };

  const handlePaginationModelChange = (newModel) => {
    setPageSize(newModel.pageSize);

    setPaginationModel(newModel);

    const convertedFilters = convertFilters(filtersState);
    const filledFilters = isFiltersWasApplied
      ? removeEmptyKeys(convertedFilters)
      : {};
    const paginationModelParams = preparePaginationModelParams(newModel);
    const params = {
      ...filledFilters,
      ...paginationModelParams,
    };

    fetchAnalyticsList(params);
  };

  useEffect(() => {
    fetchAnalyticsList({ page: START_PAGE, per_page: PAGE_SIZE_COUNT });
  }, []);

  return (
    <Box py="24px">
      <Typography variant="h2" gutterBottom>
        Аналитика
      </Typography>
      {isError && "Ooops, something went wrong..."}
      <Box width="100%">
        <DataGrid
          rows={analyticsData.map((item) => ({
            ...item,
            productName: item.coreProduct?.productName,
            countSearch: item.wordStat?.countSearch,
          }))}
          columns={analyticsGridColumns}
          initialState={{
            pagination: {
              paginationModel: DEFAULT_PAGINATION_MODEL,
            },
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          rowCount={rowNumber}
          slots={{
            toolbar: ModifiedCustomToolbar,
            loadingOverlay: LinearProgress,
          }}
          filterMode="server"
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default Analytics;
