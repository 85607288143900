import React from 'react';
import Navbar from './components/Navbar.tsx';
import useRoutes from './routes/routes.tsx';
import { Sidebar } from './components/Sidebar.tsx';
import './App.css';

function App() {
  const routes = useRoutes();

  return (
    <>
      <Navbar />
      <Sidebar />
      {routes}
    </>
  );
}

export default App;
