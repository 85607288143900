import React, { ChangeEvent, useState } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { analyticsGridColumns } from "./utils.tsx";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

export const initialFilterState = {
  code: "",
  brand: "",
  vendorCount: "",
  qtyCount: "",
  avgCost: "",
  minCost: "",
  countSearch: "",
  whiteList: "--",
};

export function AnalyticsCustomToolbar({
  filtersState,
  onApplyFilters,
  isLoading,
}) {
  const [filters, setFilters] = useState({
    ...initialFilterState,
    ...filtersState,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [e.target.id]: e.target.value,
    });
  };

  const handleClick = () => onApplyFilters(filters);

  const handleSelectChange = (e: SelectChangeEvent) => {
    setFilters({
      ...filters,
      whiteList: e.target.value,
    });
  };

  return (
    <>
      <Typography variant="h5">Фильтры</Typography>
      <GridToolbarContainer>
        {analyticsGridColumns.map(
          (item) =>
            item.filterable &&
            (item.type === "boolean" ? (
              <Box key={item.field} sx={{ minWidth: 210 }}>
                <FormControl fullWidth>
                  <InputLabel id={`${item.field}_label`}>White-List</InputLabel>
                  <Select
                    id={item.field}
                    labelId={`${item.field}_label`}
                    value={filters[item.field]}
                    label="White-List"
                    onChange={handleSelectChange}
                    size="small"
                  >
                    <MenuItem value={"--"}>Не выбрано</MenuItem>
                    <MenuItem value={"-"}>Добавлено</MenuItem>
                    <MenuItem value={"+"}>Не добавлено</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <TextField
                key={item.field}
                id={item.field}
                label={item.headerName}
                size="small"
                onChange={handleChange}
                value={filters[item.field]}
                type={item.type}
              />
            ))
        )}
      </GridToolbarContainer>
      <GridToolbarContainer>
        <Button variant="contained" onClick={handleClick} disabled={isLoading}>
          Применить фильтры
        </Button>
      </GridToolbarContainer>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}
