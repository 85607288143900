// Импортируем необходимые модули из библиотеки react-router-dom и пользовательский хук useAuth
import React from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth.tsx";

export const PrivateRoute = () => {
  const { isAuthenticated } = useAuth()

  const location = useLocation()

  return (
    // Если пользователь авторизован, то рендерим дочерние элементы текущего маршрута, используя компонент Outlet
    isAuthenticated === true ?
      <Outlet />
      // Если пользователь не авторизован, то перенаправляем его на маршрут /login с помощью компонента Navigate.
      // Свойство replace указывает, что текущий маршрут будет заменен на новый,
      // чтобы пользователь не мог вернуться обратно, используя кнопку "назад" в браузере.
      :
      <Navigate to="/login" state={{ from: location }} replace />
  )
};
