import React from "react";
import { Dialog } from "@mui/material";
import "./TableDialog.scss";

interface ITableDialogProps {
  children: any;
  isOpen: boolean;
  onClose: () => void;
}

export const TableDialog = ({
  children,
  isOpen,
  onClose,
}: ITableDialogProps) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        style: { minWidth: 400, padding: 30 },
      }}
    >
      {children}
    </Dialog>
  );
};
