import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth.tsx";
import SignIn from "../components/SignIn.tsx";
import { setAuthToken } from "../utils/localStorageAuth.ts";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const signInActions = useCallback(
    (token: string) => {
      setAuth(true);
      setAuthToken(token);
      navigate(from, { replace: true });
    },
    [from, navigate, setAuth]
  );

  return <SignIn signInActions={signInActions} />;
};

export default Login;
