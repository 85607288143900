import { B2B_GETTZAP_HOST } from "../consts/api.ts";
import { defaultHeaders } from "./utils.ts";

const WHITE_LIST_API = "/b2b/api/direct/white_lists";

interface IAddToWhiteListData {
  uid: string;
  rating: number;
  note: string;
}

export const addToWhiteListApi = (data: IAddToWhiteListData) => {
  return fetch(`${B2B_GETTZAP_HOST}${WHITE_LIST_API}`, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify(data),
  });
};

export const getWhiteListApi = (params = {}) => {
  const queryParams = "?" + new URLSearchParams(params);

  return fetch(`${B2B_GETTZAP_HOST}${WHITE_LIST_API}` + queryParams, {
    method: "GET",
    headers: defaultHeaders,
  });
};
