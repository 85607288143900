import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { WhiteListButton } from "../DataGrid/WhiteListButton.tsx";
import { CodeCell } from "../DataGrid/CodeCell.tsx";
import { AddPriceButton } from "./AddPriceButton.tsx";

export const analyticsGridColumns: GridColDef[] = [
  {
    field: "code",
    headerName: "Артикул",
    sortable: false,
    filterable: true,
    minWidth: 120,
    flex: 1,
    renderCell: (params) => <CodeCell value={params.value} />,
    type: "string",
  },
  {
    field: "brand",
    headerName: "Бренд",
    sortable: false,
    filterable: true,
    flex: 1,
  },
  {
    field: "productName",
    headerName: "Наименование",
    sortable: false,
    filterable: false,
    minWidth: 200,
    flex: 1,
    type: "string",
  },
  {
    field: "totalVendorCount",
    headerName: "Все поставщики",
    sortable: false,
    filterable: false,
    minWidth: 130,
    flex: 1,
  },
  {
    field: "totalQtyCount",
    headerName: "Все остатки",
    sortable: false,
    filterable: false,
    minWidth: 100,
    flex: 1,
  },
  {
    field: "totalAvgCost",
    headerName: "Ср. цена всех",
    sortable: false,
    filterable: false,
    minWidth: 100,
    flex: 1,
  },
  {
    field: "vendorCount",
    headerName: "Поставщики",
    filterable: true,
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "qtyCount",
    headerName: "Остатки",
    filterable: true,
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "avgCost",
    headerName: "Ср. цена",
    filterable: true,
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "minCost",
    headerName: "Мин. цена",
    filterable: true,
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "countSearch",
    headerName: "Популярность",
    filterable: true,
    minWidth: 160,
    flex: 1,
    type: "number",
  },
  {
    field: "whiteList",
    headerName: "White-List",
    sortable: false,
    filterable: true,
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <WhiteListButton params={params} />,
    type: "boolean",
  },
];

export const whiteListGridColumns: GridColDef[] = [
  {
    field: "code",
    headerName: "Артикул",
    sortable: false,
    filterable: true,
    minWidth: 120,
    flex: 1,
    renderCell: (params) => <CodeCell value={params.value} />,
    type: "string",
  },
  {
    field: "brand",
    headerName: "Имя бренда",
    sortable: false,
    filterable: true,
    flex: 1,
    type: "string",
  },
  {
    field: "productName",
    headerName: "Наименование",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "string",
  },
  {
    field: "rating",
    headerName: "Рейтинг",
    sortable: false,
    filterable: true,
    flex: 1,
    type: "number",
  },
  {
    field: "note",
    headerName: "Комментарий",
    sortable: false,
    flex: 1,
  },
  {
    field: "addPrice",
    headerName: "Добавить price",
    sortable: false,
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <AddPriceButton params={params} />,
    type: "boolean",
  },
];

export const pricesGridColumns: GridColDef[] = [
  {
    field: "code",
    headerName: "Артикул",
    sortable: false,
    filterable: true,
    minWidth: 120,
    flex: 1,
    renderCell: (params) => <CodeCell value={params.value} />,
    type: "string",
  },
  {
    field: "brand",
    headerName: "Имя бренда",
    sortable: false,
    filterable: true,
    flex: 1,
    type: "string",
  },
  {
    field: "productName",
    headerName: "Наименование",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "string",
  },
  {
    field: "sellerName",
    headerName: "Продавец",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "string",
  },
  {
    field: "replica",
    headerName: "Реплика?",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "boolean"
  },
  {
    field: "cost",
    headerName: "Цена",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "number"
  },
  {
    field: "minBatch",
    headerName: "Мин партия",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "number"
  },
  {
    field: "note",
    headerName: "Комментарий",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "string"
  },
  {
    field: "updatedAt",
    headerName: "Дата обновления",
    sortable: false,
    filterable: false,
    flex: 1,
    type: "string"
  }

];