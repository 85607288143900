import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress, Typography } from "@mui/material";
import {
  WhiteListCustomToolbar,
  initialFilterState,
} from "../components/DataGrid/WhiteListCustomToolbar.tsx";
import { whiteListGridColumns } from "../components/DataGrid/utils.tsx";
import {
  convertFilters,
  preparePaginationModelParams,
  removeEmptyKeys,
} from "../utils/prepareAnalyticQueryParams.ts";
import { getPageSize, setPageSize } from "../utils/localStoragePageSize.ts";
import { getWhiteListApi } from "../api/whiteList.ts";
import { IWhiteListItem } from "../types/whiteListItem.ts";
import useSellers from "../hooks/useSellers.tsx";
import { getSellersListApi } from "../api/sellers.ts";

const START_PAGE = 0;
const PAGE_SIZE_COUNT = Number(getPageSize()) || 100;
const DEFAULT_PAGINATION_MODEL = {
  page: START_PAGE,
  pageSize: PAGE_SIZE_COUNT,
};

const WhiteList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [whiteList, setWhiteList] = useState<IWhiteListItem[]>([]);
  const [rowNumber, setRowNumber] = useState(0);
  const [filtersState, setFiltersState] = useState(initialFilterState);
  const [paginationModel, setPaginationModel] = useState(
    DEFAULT_PAGINATION_MODEL
  );
  const [isFiltersWasApplied, setIsFiltersWasApplied] = useState(false);
  const { setSellers } = useSellers();

  const fetchWhiteList = (params?) => {
    getWhiteListApi(params).then(async (res) => {
      const json = await res.json();

      if (res.ok) {
        setWhiteList(json.data);
        setRowNumber(json.meta.count);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  };

  const onApplyFilters = (filters) => {
    setFiltersState(filters);
    setIsFiltersWasApplied(true);

    const convertedFilters = convertFilters(filters);
    const filledFilters = removeEmptyKeys(convertedFilters);
    const paginationModelParams = preparePaginationModelParams(paginationModel);
    const params = {
      ...filledFilters,
      ...paginationModelParams,
    };

    setIsLoading(true);

    fetchWhiteList(params);
  };

  const ModifiedCustomToolbar = () => {
    return (
      <WhiteListCustomToolbar
        filtersState={filtersState}
        onApplyFilters={onApplyFilters}
        isLoading={isLoading}
      />
    );
  };

  const handlePaginationModelChange = (newModel) => {
    setPageSize(newModel.pageSize);
    setPaginationModel(newModel);

    const convertedFilters = convertFilters(filtersState);
    const filledFilters = isFiltersWasApplied
      ? removeEmptyKeys(convertedFilters)
      : {};
    const paginationModelParams = preparePaginationModelParams(newModel);
    const params = {
      ...filledFilters,
      ...paginationModelParams,
    };

    fetchWhiteList(params);
  };

  useEffect(() => {
    fetchWhiteList({ page: START_PAGE, per_page: PAGE_SIZE_COUNT });

    getSellersListApi().then(async (res) => {
      const json = await res.json();

      if (res.ok) {
        setSellers(json.data);
      }
    });
  }, [setSellers]);

  return (
    <div style={{ padding: "0 24px" }}>
      <Typography variant="h2" gutterBottom>
        White List
      </Typography>
      {isError && "Ooops, something went wrong..."}
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={whiteList}
          columns={whiteListGridColumns}
          initialState={{
            pagination: {
              paginationModel: DEFAULT_PAGINATION_MODEL,
            },
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          rowCount={rowNumber}
          slots={{
            toolbar: ModifiedCustomToolbar,
            loadingOverlay: LinearProgress,
          }}
          filterMode="server"
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default WhiteList;
