import React from 'react';
import BasicTable from '../components/Table/Table.tsx';

const Admin = () => {
  return (
    <>
      <h1>admin</h1>
      <BasicTable />
    </>
  )
}

export default Admin
