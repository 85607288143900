import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useAuth from '../hooks/useAuth.tsx';
import useSidebar from '../hooks/useSidebar.tsx';

function Navbar() {
  const { isAuthenticated } = useAuth()
  const { setIsSidebarOpen } = useSidebar()

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setIsSidebarOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Gettzap direct
            </Typography>
            {isAuthenticated ? <Link to="/logout">Logout</Link> : <Link to="/login">Login</Link>}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  )
}

export default Navbar
