import React, { createContext, useState } from "react";
import { ISellersListItem } from "../types/sellersItem";

type SellersContextType = {
  sellers: ISellersListItem[];
  setSellers: (sellers: ISellersListItem[]) => void;
};

const SellersContext = createContext<SellersContextType>({
  sellers: [],
  setSellers: () => {},
});

export const SellersProvider = ({ children }: { children: JSX.Element }) => {
  const [sellers, setSellers] = useState<ISellersListItem[]>([]);

  return (
    <>
      <SellersContext.Provider value={{ sellers, setSellers }}>
        {children}
      </SellersContext.Provider>
    </>
  );
};

export default SellersContext;
