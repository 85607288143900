import { B2B_GETTZAP_HOST } from "../consts/api.ts";
import { defaultHeaders } from "./utils.ts";

const ANALYTICS_API_GET = "/b2b/api/direct/sellers";

export const getSellersListApi = (params = {}) => {
  const queryParams = "?" + new URLSearchParams(params);

  return fetch(`${B2B_GETTZAP_HOST}${ANALYTICS_API_GET}` + queryParams, {
    method: "GET",
    headers: defaultHeaders,
  });
};
