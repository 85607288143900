import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Analytics, Checklist, PriceChange } from "@mui/icons-material";
import useSidebar from "../hooks/useSidebar.tsx";
import { useNavigate } from "react-router-dom";

const routesList = [
  {
    component: <Analytics />,
    route: "/analiytics",
    name: "Аналитика",
  },
  {
    component: <Checklist />,
    route: "/white-list",
    name: "White List",
  },
  {
    component: <PriceChange />,
    route: "/prices",
    name: "Prices",
  },
];

export const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
  const navigate = useNavigate();

  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setIsSidebarOpen(false)}
    >
      <List>
        {routesList.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton onClick={() => navigate(item.route)}>
              <ListItemIcon>{item.component}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer open={isSidebarOpen} onClose={() => setIsSidebarOpen(false)}>
      {DrawerList}
    </Drawer>
  );
};
