import React from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute.tsx";
import Login from "../pages/Login.tsx";
import Admin from "../pages/Admin.tsx";
import Logout from "../pages/Logout.tsx";
import Analytics from "../pages/Analytics.tsx";
import WhiteList from "../pages/WhiteList.tsx";
import Prices from "../pages/Prices.tsx"

export const useRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route element={<PrivateRoute />}>
        <Route index element={<Admin />} />
        <Route path="/" element={<Admin />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/analiytics" element={<Analytics />} />
        <Route path="/white-list" element={<WhiteList />} />
        <Route path="/prices" element={<Prices/>} />
      </Route>
    </Routes>
  );
};

export default useRoutes;
