import { GETTZAP_HOST } from '../consts/api.ts';

const SESSIONS_API = '/api/common/v1/sessions'

const postSessions = (userData, callback) => {
  return fetch(`${GETTZAP_HOST}${SESSIONS_API}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: userData.email,
      password: userData.password,
    }),
  }).then(async (res) => callback(res));
}

export default postSessions