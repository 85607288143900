import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const CodeCell = ({ value }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onClick = () => {
    navigator.clipboard.writeText(value)
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 1000);
  }

  return (
    <Tooltip
      open={isOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="Скопировано"
    >
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={onClick}>
        <ContentCopyIcon />
        <span style={{ marginLeft: 10 }}>{value}</span>
      </div>
    </Tooltip>
  )
}