import React, { ChangeEvent, useState } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { whiteListGridColumns } from "./utils.tsx";
import { Button, TextField, Typography } from "@mui/material";

const MIN_RATING = 1;
const MAX_RATING = 10;

export const initialFilterState = {
  code: "",
  brand: "",
  vendorCount: "",
  qtyCount: "",
  avgCost: "",
  minCost: "",
  countSearch: "",
  whiteList: "--",
};

export function WhiteListCustomToolbar({
  filtersState,
  onApplyFilters,
  isLoading,
}) {
  const [filters, setFilters] = useState({
    ...initialFilterState,
    ...filtersState,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [e.target.id]: e.target.value,
    });
  };

  const handleClick = () => onApplyFilters(filters);

  return (
    <>
      <Typography variant="h5">Фильтры</Typography>
      <GridToolbarContainer>
        {whiteListGridColumns.map(
          (item) =>
            item.filterable && (
              <TextField
                key={item.field}
                id={item.field}
                label={item.headerName}
                size="small"
                onChange={handleChange}
                value={filters[item.field]}
                type={item.type}
                InputProps={
                  item.field === "rating"
                    ? {
                        inputProps: { min: MIN_RATING, max: MAX_RATING },
                      }
                    : {}
                }
                style={{ width: "100%", maxWidth: "210px" }}
              />
            )
        )}
      </GridToolbarContainer>
      <GridToolbarContainer>
        <Button variant="contained" onClick={handleClick} disabled={isLoading}>
          Применить фильтры
        </Button>
      </GridToolbarContainer>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}
