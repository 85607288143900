import {
  Button,
  Checkbox,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { TableDialog } from "./TableDialog.tsx";
import { IAddPriceData, addPriceApi } from "../../api/prices.ts";
import useSellers from "../../hooks/useSellers.tsx";

const DEFAULT_COST = 0;
const DEFAULT_MIN_BUTCH = 1;
const DEFAULT_SELLER_ID = "";

export const AddPriceButton = ({ params }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isReplica, setIsReplica] = useState<boolean>(false);
  const [cost, setCost] = useState<number>(DEFAULT_COST);
  const [minBatch, setMinBatch] = useState<number>(DEFAULT_MIN_BUTCH);
  const [sellerId, setSellerId] = useState<string>(DEFAULT_SELLER_ID);
  const [note, setNote] = useState<string>("");
  const [isSellerSelected, setIsSellerSelected] = useState<boolean>(false);
  const { sellers } = useSellers();

  const rowData = params.row;
  const { uid } = rowData;

  const onAddPriceClick = () => {
    const priceListData: IAddPriceData = {
      uid,
      replica: isReplica,
      cost,
      minBatch,
      sellerId: Number(sellerId),
      note,
    };

    addPriceApi(priceListData);
    onClose();
  };

  const onReplicaChange = (event) => {
    setIsReplica(event.target.checked);
  };

  const onCostChange = (event) => {
    setCost(event.target.value);
  };

  const onMinBatchChange = (event) => {
    setMinBatch(event.target.value);
  };

  const onSellerIdChange = (event) => {
    setSellerId(event.target.value);
    setIsSellerSelected(true);
  };

  const onTextFieldChange = (event) => {
    setNote(event.target.value);
  };

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <>
      <Button variant="contained" onClick={handleOpenPopup}>
        Добавить Price
      </Button>
      <TableDialog isOpen={isOpen} onClose={onClose}>
        <DialogTitle style={{ paddingTop: 0 }} align="center">
          Добавление price
        </DialogTitle>
        <div className="dialog-price-item">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox value={isReplica} onChange={onReplicaChange} />
              }
              label="Реплика"
            />
          </FormGroup>
        </div>
        <div className="dialog-price-item">
          <TextField
            key={uid}
            id={uid}
            label="Стоимость"
            size="small"
            onChange={onCostChange}
            value={cost}
            InputProps={{ inputProps: { min: DEFAULT_COST } }}
            type="number"
          />
        </div>
        <div className="dialog-price-item">
          <TextField
            key={uid}
            id={uid}
            label="Минимальная партия"
            size="small"
            onChange={onMinBatchChange}
            value={minBatch}
            InputProps={{ inputProps: { min: DEFAULT_MIN_BUTCH } }}
            type="number"
          />
        </div>
        <div className="dialog-price-item">
          <FormControl fullWidth error={!isSellerSelected}>
            <Select
              id={uid}
              value={sellerId}
              onChange={onSellerIdChange}
              size="small"
            >
              {sellers.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {isSellerSelected ? "Продавец" : "Обязательно укажите продавца!"}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="dialog-textfield">
          <TextField
            id="outlined-basic"
            label="Примечание"
            variant="outlined"
            fullWidth
            multiline
            maxRows={5}
            value={note}
            onChange={onTextFieldChange}
          />
        </div>
        <Button variant="contained" onClick={onAddPriceClick}>
          Добавить price
        </Button>
      </TableDialog>
    </>
  );
};
