import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { pricesGridColumns } from "../components/DataGrid/utils.tsx";
import { getPageSize } from "../utils/localStoragePageSize.ts";
import { getSellersListApi } from "../api/sellers.ts";
import { IPriceItem } from "../types/priceItem.js";
import { getPricesApi } from "../api/prices.ts";

const START_PAGE = 0;
const PAGE_SIZE_COUNT = Number(getPageSize()) || 100;
const DEFAULT_PAGINATION_MODEL = {
  page: START_PAGE,
  pageSize: PAGE_SIZE_COUNT,
};

const Prices = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [prices, setPrices] = useState<IPriceItem[]>([]);
    const [rowNumber, setRowNumber] = useState(0);
  
    const fetchPrices = (params?) => {
      getPricesApi(params).then(async (res) => {
        const json = await res.json();
  
        if (res.ok) {
          setPrices(json.data);
          setRowNumber(json.meta.count);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      });
    };

    useEffect(() => {
        fetchPrices({ page: START_PAGE, per_page: PAGE_SIZE_COUNT });
    
        getSellersListApi().then(async (res) => {
          const json = await res.json();
    
          if (res.ok) {
            setPrices(json.data);
          }
        });
      }, [setPrices]);

      return (
        <div style={{ padding: "0 24px" }}>
          <Typography variant="h2" gutterBottom>
            Prices
          </Typography>
          {isError && "Ooops, something went wrong..."}
          <div style={{ width: "100%" }}>
            <DataGrid
              rows={prices.map((item, key) =>({...item, id: key }))}
              columns={pricesGridColumns}
              initialState={{
                pagination: {
                  paginationModel: DEFAULT_PAGINATION_MODEL,
                },
              }}
              pageSizeOptions={[25, 50, 75, 100]}
              rowCount={rowNumber}
              filterMode="server"
              paginationMode="server"
              loading={isLoading}
            />
          </div>
        </div>
      );

};

export default Prices;